import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import PageHeader from "../Components/PageHeader";
import { useNavigate } from "react-router-dom";

export default function CasinoBetPageAnderBhar() {
  const navigate = useNavigate();
  const handleCasinoAllBetComplete = () => {
    navigate("/casino-ander-bhar-complete");
  };
  return (
    <>
      <div className="position-relative userTheme bg-light">
        <div className="position-relative">
          <div style={{ position: "sticky", top: 0, zIndex: 100 }}>
            <PageHeader title="LEDGER" />
          </div>
          <div className="menu mt-2" id="menu" align="center">
            <ul className="nav">
              <li className="active abc w-100">
                <Link to="/user-casino" style={{ height: "32.1px" }}>
                  BACK TO MAIN MENU
                </Link>
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-8">
              <div
                className="text-center"
                style={{
                  backgroundColor: "#2c3e50",
                  padding: "15px",
                  height: "50px",
                }}
              >
                <span
                  className="text-white"
                  align="center"
                  style={{ fontSize: "16px" }}
                >
                  {" "}
                  Round ID: 11.232708231533
                </span>
              </div>
              <iframe
                width="100%"
                height="210"
                style={{
                  border: "1px",
                  marginLeft: "-1px",
                  marginRight: "-1px",
                  marginTop: "0px",
                  marginBottom: "-5px",
                }}
                className="ng-tns-c156-3 ng-star-inserted"
                src="https://vrnl.xyz/?params=8019,AB2"
              ></iframe>
              <table className="mb-0 table-bordered table">
                <tbody>
                  <tr className="text-white">
                    <th style={{ color: "black", textAlign: "start" }}>
                      Min: 100 | Max: 25000{" "}
                    </th>
                    <th style={{ backgroundColor: "#72bbef", color: "black" }}>
                      Back
                    </th>
                  </tr>
                  <tr>
                    <td style={{ color: "black", textAlign: "start" }}>
                      Player A
                    </td>
                    <td style={{ backgroundColor: "#72bbef" }}>1.95 0 </td>
                  </tr>
                  <tr>
                    <td style={{ color: "black", textAlign: "start" }}>
                      Player B
                    </td>
                    <td style={{ backgroundColor: "#72bbef" }}>1.95 0 </td>
                  </tr>
                </tbody>
              </table>
              <div
                className="text-center"
                style={{
                  backgroundColor: "#2c3e50",
                  padding: "5px",
                  height: "33px",
                }}
              >
                <span className="text-white"> Last Result</span>
                <span className="text-white"> View All</span>
              </div>
            </div>
            <div className="col-lg-4 mt-0 ">
              <div
                style={{
                  backgroundColor: "#2c3e50",
                  padding: "5px",
                  height: "50px",
                  textAlign: "center",
                }}
              >
                <span
                  className="text-white"
                  style={{
                    padding: "15px",
                    fontSize: "17px",
                    fontWeight: "bold",
                    letterSpacing: "1px",
                    textTransform: "uppercase",
                  }}
                >
                  {" "}
                  My Bet
                </span>
              </div>
              <table className="mb-0 table-bordered table">
                <tbody>
                  <tr>
                    <th>Matched Bet</th>
                    <th>Market</th>
                    <th>Odds</th>
                    <th>Stake</th>
                  </tr>
                </tbody>
              </table>

              <div className="text-center">
                <button
                  className="btn btn-dark"
                  onClick={handleCasinoAllBetComplete}
                >
                  See all Completed bets
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",

          zIndex: 100,
        }}
      >
        <Footer />
      </div>
    </>
  );
}
