/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "./UserInplay.css";
import { Link, useNavigate } from "react-router-dom";
import Footer from "./Components/Footer";
import PageHeader from "./Components/PageHeader";
import useGetLiveMatch from "./hooks/useGetLiveMatch";
import { dateTimeFormatter } from "../utils/dateFormatter";
// import { useGetUserPlayedMatchBets } from "./hooks/useGetUserPlayedMatchBets";
// import { useGetUserPlayedMatchBetsByMatchId } from "./hooks/useGetUserPlayedMatchBetsByMatchId";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTv } from "@fortawesome/free-solid-svg-icons";
import useGetAccountStatus from "./hooks/useGetAccountStatus";
import cricketlog from "../../src/img/4.webp";
import electionlogo from "../../src/img/-5.png";

export default function UserInplay() {
  const navigate = useNavigate();
  let userId = sessionStorage.getItem("UUID");
  let userData = JSON.parse(sessionStorage.getItem("DATA")).data;
  const userPassword = sessionStorage.getItem("password");

  const uuid = sessionStorage.getItem("UUID");
  const { mutate: accountStatus } = useGetAccountStatus();

  const { mutate: getLiveMatch } = useGetLiveMatch();

  const [liveMatches, setLiveMatches] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: "user",
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userPassword !== userData.password) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        console.log(error);
        // toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userData) {
      const payload = {
        user_id: userId,
      };

      getLiveMatch(payload, {
        onSuccess: (response) => {
          const data = response.data.data;

          data.sort((a, b) => {
            return new Date(a.server) - new Date(b.server);
          });

          setLiveMatches(data);
        },

        onError: (error) => {
          console.log(error);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startBet = (item) => {
    // console.log(item);
    sessionStorage.setItem("match_id", item.id);
    sessionStorage.setItem("game_name", item.name);
    sessionStorage.setItem("team1", item.team1);
    sessionStorage.setItem("team2", item.team2);
    sessionStorage.setItem("type", item.server);
    sessionStorage.setItem("match_type", item.match_type);
    sessionStorage.setItem(
      "session_automatic",
      item.session_automatic ? "true" : "false"
    );
    sessionStorage.setItem("score_code", item.score_code);
    sessionStorage.setItem("CALLED", item.api);
    sessionStorage.setItem("tvcode", item.tv_code);
    sessionStorage.setItem("code_", item.code);
    sessionStorage.setItem("tvcodeapi", item.tv_code_api);

    navigate(`/user-inplay/start-bet?id=${item.id}`);
  };

  return (
    <>
      <div
        className="position-relative userTheme bg-light"
        style={{ overflowX: "hidden" }}
      >
        <PageHeader title="IN PLAY" />
        <div
          className="position-relative"
          style={{ paddingTop: "92px", paddingBottom: "48px" }}
        >
          <br />
          <div className="container">
            <div className="row px-0 pt-0">
              <table
                width="100%"
                border="0"
                cellPadding="2"
                cellSpacing="2"
                className="table table-bordered align-middle mb-0"
              >
                <tbody>
                  <td
                    style={{
                      backgroundColor: "#01667a",
                      color: "white",
                      width: "35%",
                    }}
                    className="p-1"
                  >
                    <img
                      src={cricketlog}
                      style={{ width: "42px" }}
                      align="left"
                    />

                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        fontSize: "18px",
                        fontWeight: "500",
                        paddingLeft: "0",
                      }}
                    >
                      Cricket
                    </span>
                  </td>
                  <td
                    className="pt-0 pb-0"
                    style={{ backgroundColor: "#0092ad", color: "white" }}
                  >
                    <img
                      src={electionlogo}
                      style={{ width: "42px" }}
                      align="left"
                    />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        fontSize: "18px",
                        fontWeight: "500",
                        paddingLeft: "0",
                      }}
                    >
                      Election
                    </span>
                  </td>
                </tbody>
                <tbody>
                  <td
                    colSpan={2}
                    style={{
                      backgroundColor: "#cbcbcb",
                      color: "white",
                      width: "30%",
                    }}
                    className="pt-0 pb-0"
                  >
                    <img
                      src={cricketlog}
                      style={{ width: "42px" }}
                      align="left"
                    />
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        color: "black",
                        fontSize: "18px",
                        fontWeight: "500",
                        paddingLeft: "0",
                      }}
                    >
                      Cricket
                    </span>
                  </td>
                </tbody>
              </table>

              {liveMatches.length > 0 ? (
                liveMatches?.map((item, index) => (
                  <div className="inplylist mb-1 pt-1" key={`inplay-${index}`}>
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        startBet(item);
                      }}
                      className="inplylistLink"
                    >
                      <table
                        width="100%"
                        border="0"
                        cellPadding="2"
                        cellSpacing="2"
                        className="table table-bordered align-middle mb-0"
                      >
                        <tbody>
                          <tr></tr>
                          <tr bgcolor="white">
                            <td
                              width="30%"
                              height="44"
                              style={{
                                color: "#01667a",
                                textAlign: "left",
                                fontSize: "12px",
                                fontWeight: "600",
                                backgroundColor: "#e5e5e5",
                              }}
                              className="FontTextWhite10px"
                              id="spantiminginplay"
                            >
                              {dateTimeFormatter(item.server)}
                              <span
                                style={{ color: "black", marginLeft: "10px" }}
                              >
                                {item.team1} VS {item.team2} ({item.match_type})
                              </span>
                              <FontAwesomeIcon
                                icon={faTv}
                                style={{ marginLeft: "10px" }}
                              />

                              <div>
                                <tr id="inplayblockboxresponsive">
                                  <td
                                    width="5%"
                                    className="FontTextWhite10px"
                                    style={{
                                      color: "black",
                                      backgroundColor: "rgb(75, 177, 252)",
                                    }}
                                  >
                                    -
                                  </td>
                                  <td
                                    width="5%"
                                    className="FontTextWhite10px"
                                    style={{
                                      color: "black",
                                      backgroundColor: "rgb(247, 148, 168)",
                                    }}
                                  >
                                    -
                                  </td>
                                  <td
                                    width="5%"
                                    className="FontTextWhite10px"
                                    style={{
                                      color: "black",
                                      backgroundColor: "rgb(75, 177, 252)",
                                    }}
                                  >
                                    -
                                  </td>
                                  <td
                                    width="5%"
                                    className="FontTextWhite10px"
                                    style={{
                                      color: "black",
                                      backgroundColor: "rgb(247, 148, 168)",
                                    }}
                                  >
                                    -
                                  </td>
                                  <td
                                    width="5%"
                                    className="FontTextWhite10px"
                                    style={{
                                      color: "black",
                                      backgroundColor: "rgb(75, 177, 252)",
                                    }}
                                  >
                                    -
                                  </td>
                                  <td
                                    width="5%"
                                    className="FontTextWhite10px"
                                    style={{
                                      color: "black",
                                      backgroundColor: "rgb(247, 148, 168)",
                                    }}
                                  >
                                    -
                                  </td>
                                </tr>
                              </div>
                            </td>

                            <td
                              width="5%"
                              className="FontTextWhite10px"
                              id="spaninplayblockresponsive"
                              style={{
                                color: "black",
                                backgroundColor: "#72BBEF",
                              }}
                            >
                              -
                            </td>
                            <td
                              width="5%"
                              className="FontTextWhite10px"
                              id="spaninplayblockresponsive"
                              style={{
                                color: "black",
                                backgroundColor: "#FAA9BA",
                              }}
                            >
                              -
                            </td>
                            <td
                              width="5%"
                              className="FontTextWhite10px"
                              id="spaninplayblockresponsive"
                              style={{
                                color: "black",
                                backgroundColor: "#72BBEF",
                              }}
                            >
                              -
                            </td>
                            <td
                              width="5%"
                              className="FontTextWhite10px"
                              id="spaninplayblockresponsive"
                              style={{
                                color: "black",
                                backgroundColor: "#FAA9BA",
                              }}
                            >
                              -
                            </td>
                            <td
                              width="5%"
                              className="FontTextWhite10px"
                              id="spaninplayblockresponsive"
                              style={{
                                color: "black",
                                backgroundColor: "#72BBEF",
                              }}
                            >
                              -
                            </td>
                            <td
                              width="5%"
                              className="FontTextWhite10px"
                              id="spaninplayblockresponsive"
                              style={{
                                color: "black",
                                backgroundColor: "#FAA9BA",
                              }}
                            >
                              -
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="inplylist mb-3">
                  <div className="teamName shadow-sm">
                    No live matches found
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
