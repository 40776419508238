/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./UserLoginCss.css";
// import { ToastContainer, toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import useLogin from "../hooks/useLogin";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function UserLogin() {
  const [uid, setUid] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const { mutate, isLoading: isLoggingIn } = useLogin();

  async function login(e) {
    e.preventDefault();
    let acc_type = "user";

    let payload = { acc_type: acc_type, code: code, password: password };

    if (code === "" || password === "") {
      return toast.error("Please fill in all fields");
    }

    mutate(payload, {
      onSuccess: (response) => {
        console.log(response);
        if (response) {
          sessionStorage.setItem("isUserLoggedIn", "true");
          localStorage.setItem("token", response.data.token);
          sessionStorage.setItem("NAME", response.data.register.data.name);
          sessionStorage.setItem("CODE", response.data.register.data.code);
          sessionStorage.setItem("UUID", response.data.register.data.id);
          sessionStorage.setItem(
            "DATA",
            JSON.stringify(response.data.register)
          );
          sessionStorage.setItem("status", response.data.register.data.status);
          toast.success("Client Login Successful");
          window.location.href = "/welcome";
        } else {
          toast.error(response?.message);
        }
      },
      onError: (error) => {
        if (error.response.data.statusCode === 405) {
          toast.error("Invalid User Id or Password");
        } else {
          toast.error(error.response.data.message);
        }
      },
    });
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="container main-background justify-content-center align-items-center d-flex">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-4 mx-auto">
              <div>
                <div className="card-header align-items-center d-flex flex-column border-bottom-0">
                  <b className="MuiTypography-root MuiTypography-h1 txtcolor">
                    <b>9EXPro</b>
                  </b>
                  <h1 className="MuiTypography-root MuiTypography-h5 MuiTypography-colorPrimary">
                    <b>Sign in</b>
                  </h1>
                </div>
                <div className="card-body">
                  <form className="BetPlayer-login-form login-form">
                    {/* <div className="form-floating mb-3">
                                            <input type="text" className="form-control " name="uid" onChange={(e) => setUid(e.target.value)} autoComplete="off" id="floatingInput" placeholder="Client Code" />
                                            <label htmlFor="floatingInput">Client Code<span className="req">*<span className="focus-bg"></span></span></label>
                                        </div> */}
                    <span className="input-group mb-3">
                      <span
                        className="input-group-text fw-bold"
                        style={{
                          height: "58px",
                          backgroundColor: "transparent",
                          // borderColor: "transparent",
                          border: "1px solid #ced4da",
                          borderRightColor: "transparent",
                        }}
                      >
                        C
                      </span>
                      <span className="form-floating mb-3">
                        <input
                          type="number"
                          className="form-control client-code-input"
                          name="uid"
                          onChange={(e) => {
                            setCode("C" + e.target.value);
                            setUid("C" + e.target.value);
                          }}
                          autoFocus
                          placeholder="Client Code"
                          style={{
                            borderLeftColor: "transparent",
                          }}
                        />
                        <label htmlFor="floatingInput">
                          Client Code
                          <span className="req">
                            *<span className="focus-bg"></span>
                          </span>
                        </label>
                      </span>
                    </span>
                    <div className="form-floating mb-3">
                      <input
                        type="password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="form-control"
                        id="floatingPassword"
                        placeholder="Password"
                      />
                      <label htmlFor="floatingPassword" className="">
                        Password<span className="req">*</span>
                      </label>
                    </div>
                    <div className="col-12">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineFormCheck"
                        />
                        <label
                          className="form-check-label ps-2"
                          htmlFor="inlineFormCheck"
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className="d-grid">
                      <button
                        onClick={login}
                        type="button"
                        className="btn btn-primary btn-block NewColor"
                      >
                        {isLoggingIn ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          "SIGN IN"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
                <center className="mt-4">Copyright © 9EXPRO 2024.</center>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="login-page">
                <div className="toast-container position-absolute top-0 end-0 p-3 position-fixed"></div>
                <div className="login-page-banner"></div>
                <h4 className="brand-name">.</h4>
                <div className="col-lg-6 login-form card">
                    <form>
                        <div className="p-4 form-input-transparent">
                            <h3 className="form-title">Login</h3>
                            <div className="position-relative mb-4"><span className="input-title">Username</span>
                                <div className="mb-3 rounded input-group">
                                    <input name="uid" type="text" onChange={ (e)=>setUid(e.target.value) } autoComplete="off" className="form-control" />
                                </div>
                            </div>
                            <div className="position-relative"><span className="input-title">Password</span>
                                <div className="mb-3 rounded input-group">
                                    <input name="password" type="password" tabindex="2" autoComplete="off" className="form-control"  onChange={ (e)=>setPassword(e.target.value) } />
                        
                            </div>
                            </div> 
                        </div>
                        <button onClick={login} type="button" className="w-100 primary-outline-btn py-3 btn btn-primary">Login</button>
                    </form>
                </div>
            </div> */}
    </>
  );
}
