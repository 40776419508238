/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import "../ClientPanel/MainMenu.css";
import { Link, useNavigate } from "react-router-dom";
import PageHeader from "./Components/PageHeader";
import Footer from "./Components/Footer";
import { dateTimeFormatter } from "../utils/dateFormatter";
import banner1 from "../../src/img/background11111.webp";
import banner2 from "../../src/img/background2222.webp";
import banner3 from "../../src/img/background333.webp";
import cricketlog from "../../src/img/4.webp";
import electionlogo from "../../src/img/-5.png";
import useGetAccountStatus from "./hooks/useGetAccountStatus";
import useGetLiveMatch from "./hooks/useGetLiveMatch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTv } from "@fortawesome/free-solid-svg-icons";

export default function MainMenu() {
  const uuid = sessionStorage.getItem("UUID");
  let userId = sessionStorage.getItem("UUID");
  const userPassword = sessionStorage.getItem("password");

  const { mutate: getLiveMatch } = useGetLiveMatch();

  const [liveMatches, setLiveMatches] = useState([]);

  const { mutate: accountStatus } = useGetAccountStatus();

  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(0);
  const banners = [banner1, banner2, banner3];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % banners.length);
  };

  // Auto-slide logic
  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide(); // Slide to the next image
    }, 2300); // Set the auto-slide interval time in milliseconds

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: "user",
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userPassword !== userData.password) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        console.log(error);
        // toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId) {
      const payload = {
        user_id: userId,
      };

      getLiveMatch(payload, {
        onSuccess: (response) => {
          const data = response.data.data;

          data.sort((a, b) => {
            return new Date(a.server) - new Date(b.server);
          });

          setLiveMatches(data);
        },

        onError: (error) => {
          console.log(error);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const startBet = (item) => {
    // console.log(item);
    sessionStorage.setItem("match_id", item.id);
    sessionStorage.setItem("game_name", item.name);
    sessionStorage.setItem("team1", item.team1);
    sessionStorage.setItem("team2", item.team2);
    sessionStorage.setItem("type", item.server);
    sessionStorage.setItem("match_type", item.match_type);
    sessionStorage.setItem(
      "session_automatic",
      item.session_automatic ? "true" : "false"
    );
    sessionStorage.setItem("score_code", item.score_code);
    sessionStorage.setItem("CALLED", item.api);
    sessionStorage.setItem("tvcode", item.tv_code);
    sessionStorage.setItem("code_", item.code);
    sessionStorage.setItem("tvcodeapi", item.tv_code_api);

    navigate(`/user-inplay/start-bet?id=${item.id}`);
  };

  return (
    <div>
      <PageHeader />

      <div
        id="carouselExampleInterval"
        className="carousel slide carousel-fade"
        style={{ paddingTop: "92px" }}
      >
        <div className="carousel-inner">
          {banners.map((banner, index) => (
            <div
              key={index}
              className={`carousel-item ${
                index === activeIndex ? "active" : ""
              }`}
            >
              <img
                src={banner}
                className="d-block w-100"
                alt={`banner-${index}`}
              />
            </div>
          ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          onClick={() =>
            setActiveIndex((activeIndex - 1 + banners.length) % banners.length)
          }
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          onClick={nextSlide}
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="container mb-4" style={{ paddingBottom: "48px" }}>
        <div className="row px-0 pt-0">
          <table
            width="100%"
            border="0"
            cellPadding="2"
            cellSpacing="2"
            className="table table-bordered align-middle mb-0"
          >
            <tbody>
              <td
                style={{
                  backgroundColor: "#01667a",
                  color: "white",
                  width: "35%",
                }}
                className="p-1"
              >
                <img src={cricketlog} style={{ width: "42px" }} align="left" />

                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "18px",
                    fontWeight: "500",
                    paddingLeft: "0",
                  }}
                >
                  Cricket
                </span>
              </td>
              <td
                className="pt-0 pb-0"
                style={{ backgroundColor: "#0092ad", color: "white" }}
              >
                <img
                  src={electionlogo}
                  style={{ width: "42px" }}
                  align="left"
                />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    fontSize: "18px",
                    fontWeight: "500",
                    paddingLeft: "0",
                  }}
                >
                  Election
                </span>
              </td>
            </tbody>
            <tbody>
              <td
                colSpan={2}
                style={{
                  backgroundColor: "#cbcbcb",
                  color: "white",
                  width: "30%",
                }}
                className="pt-0 pb-0"
              >
                <img src={cricketlog} style={{ width: "42px" }} align="left" />
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    color: "black",
                    fontSize: "18px",
                    fontWeight: "500",
                    paddingLeft: "0",
                  }}
                >
                  Cricket
                </span>
              </td>
            </tbody>
          </table>

          {liveMatches.length > 0 ? (
            liveMatches?.map((item, index) => (
              <div className="inplylist mb-1 pt-1" key={`inplay-${index}`}>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    startBet(item);
                  }}
                  className="inplylistLink"
                >
                  <table
                    width="100%"
                    border="0"
                    cellPadding="2"
                    cellSpacing="2"
                    className="table table-bordered align-middle mb-0"
                  >
                    <tbody>
                      <tr></tr>
                      <tr bgcolor="white">
                        <td
                          width="30%"
                          height="44"
                          style={{
                            color: "#01667a",
                            textAlign: "left",
                            fontSize: "12px",
                            fontWeight: "600",
                            backgroundColor: "#e5e5e5",
                          }}
                          className="FontTextWhite10px"
                          id="spantiminginplay"
                        >
                          {/* Main content */}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>
                              <div>{dateTimeFormatter(item.server)}</div>
                              <span style={{ color: "black" }}>
                                {item.team1} VS {item.team2} ({item.match_type})
                              </span>
                              <FontAwesomeIcon
                                icon={faTv}
                                style={{ marginLeft: "10px" }}
                              />
                            </div>

                            <div
                              style={{
                                display: "flex",
                                gap: "5px",
                                width: "40%",
                              }}
                            >
                              <div
                                style={{
                                  width: "40%",
                                  color: "black",
                                  backgroundColor: "rgb(75, 177, 252)",
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                id="spaninplayblockresponsive"
                              >
                                -
                              </div>
                              <div
                                style={{
                                  width: "40%",
                                  color: "black",
                                  backgroundColor: "rgb(247, 148, 168)",
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                id="spaninplayblockresponsive"
                              >
                                -
                              </div>
                              <div
                                style={{
                                  width: "40%",
                                  color: "black",
                                  backgroundColor: "rgb(75, 177, 252)",
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                id="spaninplayblockresponsive"
                              >
                                -
                              </div>
                              <div
                                style={{
                                  width: "40%",
                                  color: "black",
                                  backgroundColor: "rgb(247, 148, 168)",
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                id="spaninplayblockresponsive"
                              >
                                -
                              </div>
                              <div
                                style={{
                                  width: "40%",
                                  color: "black",
                                  backgroundColor: "rgb(75, 177, 252)",
                                  textAlign: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                id="spaninplayblockresponsive"
                              >
                                -
                              </div>
                              <div
                                style={{
                                  width: "40%",
                                  height: "30px",
                                  color: "black",
                                  backgroundColor: "rgb(247, 148, 168)",
                                  textAlign: "center",

                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                id="spaninplayblockresponsive"
                              >
                                -
                              </div>
                            </div>
                          </div>

                          <div>
                            <tr id="inplayblockboxresponsive">
                              <td
                                width="5%"
                                className="FontTextWhite10px"
                                style={{
                                  color: "black",
                                  backgroundColor: "rgb(75, 177, 252)",
                                }}
                              >
                                -
                              </td>
                              <td
                                width="5%"
                                className="FontTextWhite10px"
                                style={{
                                  color: "black",
                                  backgroundColor: "rgb(247, 148, 168)",
                                }}
                              >
                                -
                              </td>
                              <td
                                width="5%"
                                className="FontTextWhite10px"
                                style={{
                                  color: "black",
                                  backgroundColor: "rgb(75, 177, 252)",
                                }}
                              >
                                -
                              </td>
                              <td
                                width="5%"
                                className="FontTextWhite10px"
                                style={{
                                  color: "black",
                                  backgroundColor: "rgb(247, 148, 168)",
                                }}
                              >
                                -
                              </td>
                              <td
                                width="5%"
                                className="FontTextWhite10px"
                                style={{
                                  color: "black",
                                  backgroundColor: "rgb(75, 177, 252)",
                                }}
                              >
                                -
                              </td>
                              <td
                                width="5%"
                                className="FontTextWhite10px"
                                style={{
                                  color: "black",
                                  backgroundColor: "rgb(247, 148, 168)",
                                }}
                              >
                                -
                              </td>
                            </tr>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Link>
              </div>
            ))
          ) : (
            <div className="inplylist mb-4">
              <div className="teamName shadow-sm">No live matches found</div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
