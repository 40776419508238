// src/Sidebar.js
import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import JmdLogo from "../../img/jmdlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faBook,
  faTv,
  faUser,
  faHouseUser,
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div
      className={`sidebar ${isOpen ? "open" : "closed"} p-0`}
      style={{ backgroundColor: "white" }}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          padding: "12px 8px",
          backgroundColor: "#01667a",
        }}
      >
        <img src={JmdLogo} alt="" style={{ width: "120px", height: "44px" }} />

        <span
          className="close-btn text-white fw-light"
          style={{ lineHeight: "100%", paddingBottom: "4px" }}
          onClick={toggleSidebar}
        >
          x
        </span>
      </div>

      <ul align="start">
        <Link to="/MainMenu">
          <li className="sidebariconname">
            <div className="iconContainer" style={{ width: "20px" }}>
              <FontAwesomeIcon
                icon={faHouseUser}
                width={"20px"}
                height={"20px"}
              />
            </div>
            <span className="titleContainer">Dashboard</span>
          </li>
        </Link>
        <Link to="/user-profile">
          <li className="sidebariconname">
            <div className="iconContainer" style={{ width: "20px" }}>
              <FontAwesomeIcon icon={faUser} width={"20px"} height={"20px"} />
            </div>
            <span className="titleContainer">Profile</span>
          </li>
        </Link>
        <Link to="/welcome">
          <li className="sidebariconname">
            <div className="iconContainer" style={{ width: "20px" }}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                width={"20px"}
                height={"20px"}
              />
            </div>
            <span className="titleContainer">Rules</span>
          </li>
        </Link>

        <Link to="/user-ledger">
          <li className="sidebariconname">
            <div className="iconContainer" style={{ width: "20px" }}>
              <FontAwesomeIcon icon={faBook} width={"20px"} height={"20px"} />
            </div>
            <span className="titleContainer">Report</span>
          </li>
        </Link>
        <Link to="/user-inplay">
          <li className="sidebariconname">
            <div className="iconContainer" style={{ width: "20px" }}>
              <FontAwesomeIcon icon={faTv} width={"20px"} height={"20px"} />
            </div>
            <span className="titleContainer">Live</span>
          </li>
        </Link>
      </ul>
    </div>
  );
};

export default Sidebar;
