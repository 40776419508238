import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { Toaster } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { faGamepad } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

export default function Footer() {
  const navigate = useNavigate();

  const logout = () => {
    //alert("my fun call == ");
    localStorage.clear();
    sessionStorage.clear();
    toast.success("Logout Successfully");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        zIndex: 999,
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Toaster position="top-right" reverseOrder={false} />

      <div
        className=""
        id="footergappage"
        style={{
          backgroundColor: "#0092ad",
          height: "56px",
          display: "flex",
          justifyContent: "space-between", // Ensures equal spacing
          alignItems: "center",
          padding: "0 50px", // Adds padding on the left and right
          width: "100%", // Make sure it covers the full screen width
        }}
      >
        <Link to="/user-ledger">
          <FontAwesomeIcon
            icon={faBook}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#ffffff", fontSize: "10px" }}>
              Acc. stmt.
            </span>
          </div>
        </Link>
        <Link to="/user-inplay">
          <FontAwesomeIcon
            icon={faPlay}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#ffffff", fontSize: "10px" }}>InPlay</span>
          </div>
        </Link>

        <Link to="/MainMenu">
          <FontAwesomeIcon
            icon={faHouse}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#ffffff", fontSize: "10px" }}>Home</span>
          </div>
        </Link>
        <Link to="/user-completegame-list">
          <FontAwesomeIcon
            icon={faGamepad}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#ffffff", fontSize: "10px" }}>Complete</span>
          </div>
        </Link>

        <Link to="/user-ledger">
          <FontAwesomeIcon
            icon={faBook}
            size="2xl"
            style={{ color: "#ffffff" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#ffffff", fontSize: "10px" }}>Ledger</span>
          </div>
        </Link>
      </div>
    </div>
  );
}
